<template>
  <div class="roll-info">
    <div class="block roll-head">
      <div class="title-box">{{ data.name }}</div>
      <div class="pic-box">
        <div class="header">
          <img :src="data.user.avatar" alt="" />
        </div>
        <span>{{ data.name }}</span>
      </div>
      <div class="title-bottom">
        <div class="tips-box">
          <div class="limit" v-show="data.type == '0'">
            说明：在 {{ data.end_time }} 前确保您的账户充值满<img
              style="width: 0.2rem; margin: 0 0.05rem; vertical-align: sub"
              src="@/assets/images/about/user/huobi.png"
              alt=""
            />{{
              data.min_recharge
            }}，如果您遵循了此条件，您将获得这个房间参与资格。当抽奖的计时结束时，我们的系统会随机抽取中奖者。
          </div>
          <div class="limit" v-show="data.type == '1'">
            说明：在 {{ data.end_time }} 前确保您的账户充值满<img
              style="width: 0.2rem; margin: 0 0.05rem; vertical-align: sub"
              src="@/assets/images/about/user/huobi.png"
              alt=""
            />{{
              data.min_recharge
            }}，如果您遵循了此条件，您将获得这个房间参与资格。当抽奖的计时结束时，我们的系统会随机抽取中奖者。
          </div>
          <div class="MiaoShu" v-show="data.describe != ''">
            {{ data.describe }}
          </div>
        </div>
        <div class="btn-box">
          <div class="btn" v-show="data.status == 0" @click="JoinHome()">
            立即参与
          </div>
        </div>
      </div>
    </div>
    <div class="block item-box" v-show="data.status == 1"></div>

    <div class="item-box">
      <!-- <div v-show="data.status == 0" class="title1">
        总数{{ data.awards?.length }}件 总价值 <span><img style="width: 0.2rem;margin-right: 0.05rem;vertical-align: sub;" src="@/assets/images/about/user/huobi.png" alt="">{{ data.award_bean }}</span>
      </div> -->
      <div class="titlebox" v-if="data.status != 1">
        <div class="titlebg">
          <span>奖池详情</span>
        </div>
        <div class="titletext">
          总数{{ data.awards?.length }}件
          <!-- 总数{{ data.awards?.length }}件 总价值
          <span
            ><img
              style="width: 0.12rem; margin-right: 0.05rem; vertical-align: sub"
              src="@/assets/images/about/user/huobi.png"
              alt=""
            />{{ data.award_bean }}</span
          > -->
        </div>
      </div>
      <div v-if="data.status == 1" class="titlebox">
        <div class="titlebg">
          <span>获奖用户</span>
        </div>
      </div>
      <div class="item-list">
        <div
          v-for="(value, key) in data.awards"
          :class="'item'"
          :style="{ marginBottom: `${data.status == 1 ? '0.2rem' : ''}` }"
          :key="key"
        >
          <div class="pic">
            <img
              class="img"
              :src="value.box_record.cover"
              alt=""
              style="display: block"
            />
          </div>
          <div class="info">
            <div class="name">
              {{ value.box_record.name }} ({{ value.box_record.dura_alias }})
            </div>
            <div style="display: flex; justify-content: center">
              <!-- <div class="price"><img style="width: 0.2rem;margin-right: 0.05rem;"
                  src="@/assets/images/about/user/huobi.png" alt=""> {{ value.bean }}</div> -->
              <div class="price">
                <!-- <Cprice :price="value.bean" /> -->
              </div>
            </div>
          </div>
          <div class="getuserbox" v-show="data.status == 1">
            <img
              :src="
                value.box_record.user?.avatar
                  ? value.box_record.user?.avatar
                  : ''
              "
              alt=""
            />
            <!-- <div class="getusername">{{ value.box_record.user?.name }}</div> -->
            <div
              class="getusername"
              v-if="value.box_record.user?.id != user.id"
            >
              {{
                `${String(value.box_record.user?.id).slice(0, 2)}**${String(
                  value.box_record.user?.id
                ).slice(4)}`
              }}
            </div>
            <div class="getusername" style="color: #fff900;font-weight: 700;font-size: 0.18rem;" v-else>
              {{ value.box_record.user?.id }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="item-box" v-if="data.status == 0">
      <div class="titlebox">
        <div class="titlebg">
          <span>参与玩家</span>
        </div>
        <div class="titletext">{{ data.join_number }}人</div>
      </div>

      <div class="user-list">
        <div class="item" v-for="(value, key) in data.users" :key="key">
          <div class="header">
            <img :src="value.user?.avatar" alt="" />
          </div>
          <!-- <div class="name">{{ value.user?.name }}</div> -->
          <div class="name">
            {{
              `${String(value.user?.id).slice(0, 2)}**${String(
                value.user?.id
              ).slice(4)}`
            }}
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div class="input-box" v-show="input">
        <div class="box">
          <div class="puttitle">请输入Roll房密码</div>
          <div class="putline"></div>
          <div class="pwd">
            <el-input
              placeholder="请输入密码"
              v-model="pwdvalue"
              show-password
            ></el-input>
          </div>
          <div class="btn closeenter" @click="closeinput">
            <span>取消</span>
          </div>
          <div class="btn verify" @click="JoinHome2()"><span>确认</span></div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { SRollInfo, SJoinHome } from "@/network/server";
import myMixin from "@/utils/mixin";
import { mapState } from "vuex";
export default {
  mixins: [myMixin],
  data() {
    return {
      data: {
        name: "",
        start_time: "",
        end_time: "",
        status: 0,
        box_records: [],
        user: {
          avarar: "",
        },
        room_users: [],
        join_number: 0,
        award_bean: 0,
        is_pwd: "",
      },
      input: false,
      pwdvalue: "",
    };
  },

  created() {
    this.RollInfo();
  },
  watch: {
    $route(to, from) {
      if (to.name == "room") {
        this.RollInfo();
      }
    },
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    closeinput() {
      this.input = false;
      this.pwdvalue = "";
    },
    RollInfo() {
      SRollInfo(this.$route.query.id).then((data) => {
        this.data = data;
      });
    },
    JoinHome() {
      if (this.data.is_pwd != 0) {
        this.input = true;
      } else {
        SJoinHome(this.$route.query.id)
          .then((data) => {
            this.Success("加入成功");
            this.RollInfo();
          })
          .catch((err) => {
            this.Err(err.message);
          });
      }
    },
    JoinHome2() {
      SJoinHome(this.$route.query.id, this.pwdvalue)
        .then((data) => {
          this.Success("加入成功");
          this.RollInfo();
          this.input = false;
          this.pwdvalue = "";
        })
        .catch((err) => {
          this.Err(err.message);
          this.input = false;
          this.pwdvalue = "";
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s;
}

.fade-enter,
.fade-leave-to {
  transform: translateX(3rem);
  opacity: 0;
}

.roll-info {
  margin-top: 0.9rem !important;
  overflow: hidden;
  width: 14rem;
  margin: 0 auto;
  .block {
    position: relative;
    margin: 0.15rem 0 0.2rem;
    padding: 0.02rem;
    /*overflow: hidden;*/

    .info {
      margin: 0.2rem auto;
      text-align: center;
    }
  }

  .roll-head {
    background-size: 100% 100%;
    padding: 0 0.4rem;
    background-image: url("../../assets/images/Roll/room/pc-rolldetail-infobg.png");
    height: 2.79rem;
    .title-box {
      line-height: 0.43rem;
      height: 0.43rem;
      font-size: 0.22rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
    }

    .pic-box {
      display: flex;
      align-items: center;
      height: 1.25rem;

      span {
        margin-left: 0.34rem;
        font-size: 0.18rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
      }
    }

    .header {
      width: 0.86rem;
      height: 0.86rem;
      background: #6c57ad;
      border-radius: 50%;
      text-align: center;
      line-height: 1.2rem;
      font-size: 0.32rem;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 0.82rem;
        height: 0.82rem;
        border-radius: 50%;
      }
    }

    .title-bottom {
      display: flex;
      height: 0.95rem;
      font-size: 0.14rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      box-sizing: border-box;
      padding: 0.14rem 0 0.14rem;

      .tips-box {
        width: 80%;
        padding-right: 1rem;
        line-height: 0.25rem;
      }
    }

    .name {
      margin: 0.2rem 0;
    }

    .MiaoShu {
      white-space: nowrap; //不换行
      overflow: hidden; //超出隐藏
      text-overflow: ellipsis; //变成...
    }

    .time {
      font-size: 0.2rem;
      margin-bottom: 0.2rem;
    }

    .btn-box {
      width: 20%;
      display: flex;
      justify-content: center;
    }

    .btn {
      width: 1.66rem;
      height: 0.37rem;
      text-align: center;
      line-height: 0.37rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 0.18rem;
      color: #ffffff;
      background: linear-gradient(135deg, #180f3b 0%, #301c80 100%);
      border: 0.01rem solid #a07eff;
      cursor: pointer;
    }
  }

  .title1 {
    width: fit-content;
    // width: 4.5rem;
    height: 0.56rem;
    line-height: 0.56rem;
    background: url(../../assets/images/Roll/room/title1.png) no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding-left: 0.8rem;
    padding-right: 0.5rem;
    text-indent: 0.5rem;
    font-size: 0.14rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #cccccc;
    margin-top: 0.79rem;
    margin-left: 0.4rem;

    span {
      color: #ff9933;
    }
  }

  .huojiang {
    width: 4.5rem;
    height: 0.56rem;
    line-height: 0.56rem;
    background: url(../../assets/images/Roll/room/hjyh.png) no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding-left: 0.8rem;
    padding-right: 0.5rem;
  }

  .title2 {
    background: url(../../assets/images/Roll/room/title2.png) no-repeat;
    background-size: 100% 100%;
    width: 3.5rem;
    text-align: right;
  }

  .item-box {
    .titlebox {
      width: 100%;
      display: flex;
      align-items: center;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 0.12rem;
      color: #ffffff;
      margin-top: 0.3rem;

      .titlebg {
        width: 1.23rem;
        height: 0.25rem;
        background: url("../../assets/images/Roll/room/pc-rolldetail-titlebg.png")
          no-repeat;
        background-size: 100% 100%;
        text-align: center;
        line-height: 0.25rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 0.2rem;
        color: #ffffff;
        margin-right: 0.08rem;
      }
      .titletext {
        height: 0.25rem;
        line-height: 0.35rem;
      }
    }
    .awards-list {
      display: flex;
      flex-wrap: wrap;
      position: relative;

      .line {
        position: relative;
        width: 2rem;
        overflow: hidden;
        margin-right: 0.39rem;
        background-color: #131426;
        /*background: url(../../assets/images/public/back_pub.jpg) no-repeat center;*/
        background-size: 100% 100%;
        margin-bottom: 0.3rem;

        &:nth-child(6n) {
          margin-right: 0;
        }

        .award {
          width: 2rem;

          text-align: center;
          background-size: 100% 100%;

          .pic {
            width: 100%;
            height: 1.2rem;
            background-repeat: no-repeat;
            background-size: 110%;
            background-position: center;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              max-width: 80%;
              max-height: 80%;
            }
          }

          .name {
            width: 100%;
            padding: 0 0.05rem;
            box-sizing: border-box;
            margin-bottom: 0.16rem;
            font-size: 0.5em;
          }

          .quality {
            width: 100%;
            font-size: 0.5em;
          }

          .price {
            font-size: 0.14rem;
            margin: 0.1rem;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        .user {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 0.12rem;

          .header {
            width: 0.3rem;
            height: 0.3rem;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 0.05rem;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .name {
            font-size: 0.5em;
          }
        }
      }
    }

    @for $i from 1 through 5 {
      .roll-lv#{$i} {
        background-image: url(lv-img($i));
      }
    }

    .item-list {
      display: flex;
      flex-wrap: wrap;
      box-sizing: border-box;
      padding-bottom: 0.3rem;
      .item {
        width: 2.1rem;
        height: 1.6rem;
        margin-right: 0.22rem;
        margin-top: 0.32rem;
        background-size: 100% 100%;
        background-color: #131426;
        background: url(../../assets/images/public/back_pub-new4.png) no-repeat
          center;
        background-size: 100% 100%;
        .pic {
          width: 100%;
          height: 1rem;
          margin: 0 auto;
          display: flex;
          justify-content: center;
          align-items: center;
          .img {
            width: 1.4rem;
          }
        }

        .name {
          width: 100%;
          font-size: 1em;
          text-align: center;
          box-sizing: border-box;
          padding: 0 0.15rem;
          margin-top: 0.05rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 0.14rem;
          color: #ffffff;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          -o-text-overflow: ellipsis;
        }

        .quality {
          width: 100%;
          font-size: 0.5em;
        }

        .price {
          font-size: 0.14rem;
          display: flex;
          align-items: center;
          padding: 0 0.15rem;
          box-sizing: border-box;
          margin-top: 0.05rem;
          color: #edc114;
          height: 0.25rem;
          img {
            width: 0.2rem;
            height: 0.2rem;
            vertical-align: middle;
            margin: 0;
            margin-right: 0.03rem;
          }
        }

        .info {
          .user {
            width: 18%;

            img {
              width: 0.28rem;
              height: 0.28rem;
              border-radius: 50%;
            }
          }
        }
        .getuserbox {
          width: 100%;
          height: 0.28rem;
          margin-top: 0.2rem;
          background: linear-gradient(
            90deg,
            rgba(40, 31, 94, 0) 0%,
            #281f5e 51%,
            rgba(40, 31, 94, 0) 100%
          );
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 0.32rem;
            height: 0.32rem;
            border-radius: 50%;
            margin-right: 0.08rem;
          }
          .getusername {
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 0.14rem;
            color: #ffffff;
          }
        }
      }
    }

    .user-list {
      display: flex;
      flex-wrap: wrap;

      .item {
        width: 1rem;
        margin: 0.1rem;
        text-align: center;

        .header {
          margin: 0.1rem;

          img {
            width: 0.82rem;
            height: 0.82rem;
            border: 0.03rem solid #301b6e;
            border-radius: 50%;
          }
        }

        .name {
          width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 0.14rem;
          color: #ffffff;
        }
      }
    }
  }

  .input-box {
    position: fixed;
    top: 1.3rem;
    right: 1rem;
    box-shadow: 0.02rem 0.02rem 0.1rem rgb(0, 0, 0);
    z-index: 200;

    .box {
      width: 5.3rem;
      height: 2rem;
      background: rgba(4, 12, 34, 1);
      border-radius: 0.04rem;
      border: 0.02rem solid #5d5d9b;
      .puttitle {
        width: 100%;
        height: 0.52rem;
        line-height: 0.52rem;
        text-align: center;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 0.2rem;
        color: #ffffff;
      }
      .putline {
        width: 100%;
        border: 0.01rem solid;
        border-image: linear-gradient(
            90deg,
            rgba(93, 93, 155, 0),
            rgba(93, 93, 155, 1),
            rgba(93, 93, 155, 0)
          )
          1 1;
      }
      .pwd {
        width: 100%;
        height: 0.9rem;
        display: flex;
        justify-content: center;
        align-items: center;
        ::v-deep .el-input {
          display: flex;
          justify-content: center;
        }
        ::v-deep .el-input__inner {
          width: 3.46rem;
          height: 0.35rem;
          background: #08081e;
          border: 0.01rem solid #5d5d9b;
          font-family: PingFang SC, PingFang SC;
          font-weight: 300;
          font-size: 0.1rem;
          color: rgba(191, 191, 237, 0.5);
        }
        ::v-deep .el-input__suffix {
          right: 1rem;
        }
      }

      // @include input(rgb(218, 218, 218));

      .btn {
        position: absolute;
        box-sizing: border-box;
        width: 0.98rem;
        height: 0.28rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 0.12rem;
        color: #ffffff;
        text-align: center;
        line-height: 0.28rem;
        cursor: pointer;
      }

      .closeenter {
        left: 1.2rem;
        top: 1.4rem;
        background: linear-gradient(135deg, #282845 100%, #282845 100%);
      }

      .verify {
        right: 1.2rem;
        top: 1.4rem;
        background: linear-gradient(135deg, #242448 100%, #42426d 100%);
      }
    }
  }
}
</style>
